<template>
    <section style="background-color: #F9F9F9 0% 0% no-repeat padding-box;" class="py-2 mt-2 selectedButtonCreateUser">
      <div class="grid grid-cols-1 lg:grid-cols-3 text-center w-full">
        <div class="w-full text-left">
          <div class="buttonBack">
            <Button
              @click="$router.back()"
              style="background-color: #F8F8F8; border: transparent"
              class="mr-4 mb-2 buttonBack font-medium"
              label="Volver"
              icon="pi pi-angle-left" />
          </div>
        </div>
        <div class="text-2xl font-bold flex items-center text-gray-500 w-full justify-center">
          <i class="pi pi-info-circle mr-4 text-2xl"></i>
          <h1>Información</h1>
        </div>
        <div class="xl:flex w-full lg:justify-end">
          <Button v-if="$can('usuarios.usuarios.access')" style="background-color: #1C3FAA" @click="$router.push({name: 'usuarios.usuarios.editar.informacion', params: {id: $route.params.id}})" class="mr-4 mb-2" label="Editar usuario" icon="pi pi-pencil" />
        </div>
      </div>
      <SelectButton
        @click="active(value3.value)"
        v-model="value3"
        :options="justifyOptions"
        optionLabel="value"
        dataKey="value"
        aria-labelledby="custom"
        class="w-100"
        >
          <template class="transition duration-700 ease-in-out" #option="slotProps">
            {{ slotProps.option.name }}
              <i :class="slotProps.option.icon"></i>
          </template>
      </SelectButton>
      <div>
        <div v-if="tab === 1">
          <InformacionCrearUsuarios />
        </div>
        <div v-if="tab === 2" class="w-full">
          <PermisosCrearUsuarios />
        </div>
      </div>
    </section>
  </template>
  <script>
    import { onMounted, ref, defineAsyncComponent, computed } from 'vue'
    import usuariosStore from '../../../../../stores/usuarios.store'
    import permisosStore from '../../../../../stores/permisos.store'
    import { useRoute } from 'vue-router'
    export default {
    name: 'menuVerUsuarios',
    components: {
      InformacionCrearUsuarios: defineAsyncComponent(
      () => import('./pages/informacion/index.vue')),

      PermisosCrearUsuarios: defineAsyncComponent(
      () => import('./pages/permisos/index.vue')
    )
    },
    setup () {
      // Services
      const route = useRoute()
      // References
      const value3 = ref({ name: 'Información Básica', value: 1 })
      const tab = ref(1)
      const justifyOptions = ref([
          { name: 'Información Básica', value: 1 },
          { name: 'Permisos', value: 2 }
      ])
      // Methods
      const active = (a) => {
        tab.value = a
      }
      // Computed
      const listarUsuarios = computed(() => usuariosStore.getters.usuarios.rows)
      onMounted(() => {
        usuariosStore.dispatch('find', parseInt(route.params.id)).then(() => {
          permisosStore.dispatch('listarPermisosPorGrupo').then(() => {
            permisosStore.dispatch('validarPermisosIndividuales', parseInt(route.params.id))
          })
        })
      })
      return { value3, justifyOptions, active, tab, listarUsuarios }
    }
  }
  </script>
  <style scoped>
    ::v-deep(.p-selectbutton .p-button.p-highlight){
      background: transparent;
      border-color: transparent;
      color: #1C3FAA;
      border-bottom: 2px solid #1C3FAA;
      font-weight: 600;
      font: normal normal bold 17px/20px Roboto;
      width: 50%;
      place-content: center;
    }
    ::v-deep(.p-selectbutton .p-button.p-highlight:hover){
      background: transparent;
      border-color: transparent;
      color: #1C3FAA;
      border-bottom: 2px solid #1C3FAA;
      font-weight: 600;
      font: normal normal bold 17px/20px Roboto;
    }
    ::v-deep(.p-selectbutton .p-button){
      background: transparent;
      border-color: transparent;
      color: var(--unnamed-color-7f7e81);
      font: normal normal normal 14px/16px Roboto;
      width: 50%;
      place-content: center;
     }
    ::v-deep(.p-selectbutton .p-button:hover){
      background: transparent;
      border-color: transparent;
      color: var(--unnamed-color-7f7e81);
      font: normal normal normal 14px/16px Roboto;
    }
    .buttonBack {
      color: #7F7E81;
    }
    .buttonBack:hover {
      color: #1C3FAA;
    }
  </style>
